@import "style/utils";
@import "style/typo-mixins";

.root {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100vh;
  padding-right: 20px;
  padding-left: 20px;
}

.title {
  @include TBold12;

  position: relative;
  width: 100%;
  margin-bottom: 100px;
  font-weight: 500;
  font-size: 15px;
  text-align: center;

  &::before {
    content: "404";
    position: absolute;
    top: 50%;
    left: 0;
    z-index: 0;
    display: block;
    width: 100%;
    color: #808288;
    font-weight: 100;
    font-size: 100px;
    text-align: center;
    transform: translate(0, -50%);
  }

  span {
    position: relative;
    z-index: 1;
    display: block;
    text-align: center;
  }
}

.button {
  @include FlexCentered;
  @include TBold12;

  width: 100%;
  max-width: 320px;
  border-radius: 0;
}
